/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.6.0) components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";

.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 576px) {
  .score-table {
    td,
    th {
      font-size: 8px !important;
    }
    .btn {
      font-size: 8px !important;
    }
  }
  .score-table__ran {
    width: 50px !important;
  }
}

.public-navbar {
  padding: 0.5rem !important;
  background: white !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.certificate {
  z-index: -10;

  width: 3374px !important;
  height: 2625px !important;
  background-image: url("../img/Certificate.png");
  .certificate__badge {
    width: 15% !important;
    margin-top: 85px !important;
  }

  .certificate__description {
    margin-top: 210px !important;
    h1 {
      font-size: 200px !important;
      font-weight: 700px !important;
      color: #e29327 !important;
      text-transform: uppercase !important;
    }
    h2 {
      font-size: 50px !important;
      font-weight: 500px !important;
    }
  }

  .certificate__qr {
    margin-top: 180px !important;
    right: 400px !important;
    h2 {
      font-size: 65px !important;
    }
  }
}

.badge__main {
  .spinner-border {
    position: absolute;
    top: 50% !important;
  }
  @media screen and (max-width: 500px) {
    // img {
    // 	width: 200px !important;
    // }
  }
}

.certificate__generated {
  margin-top: 50px !important;
  padding-right: 50px !important;
  h1 {
    font-size: 35px !important;
  }
}

.payment-modal {
  ul {
    color: #32325d !important;
    font-size: 18px !important;
  }
  h1 {
    font-size: 24px !important;
    font-weight: bold !important;
  }
  p {
    font-size: 18px !important;
    color: #32325d !important;
    font-weight: 400 !important;
  }
}

.payment-toast {
  .Toastify__toast-body {
    align-items: start !important;
  }
  width: 100%;
  max-width: 768px;
}

// .Toastify__toast-container--top-right > .payment-toast {
//   width: 500px !important;
// }

@media (max-width: 768px) {
  .payment-modal {
    ul {
      color: #32325d !important;
      font-size: 14px !important;
    }
    h1 {
      font-size: 18px !important;
    }
    p {
      font-size: 14px !important;
    }
  }
}
