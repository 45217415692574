.collapse.navbar-collapse::before {
	display: none !important;
}
body {
	font-family: 'Nunito', sans-serif;
	background-color: #fafafa !important;
}
.fill-default {
	fill: #fafafa;
}
.loading-container {
	min-height: 320px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.h-full {
	height: 100vh;
}
.timer {
	width: 40px;
	height: 40px;
	border: 5px solid black;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-weight: 900;
	background-color: rgb(234, 234, 234);
	color: #000;
	font-size: 12px;
}
.header.bg-gradient-info {
	background: linear-gradient(87deg, #DF7272 0, #DF7272 100%) !important;
}
.auth-form {
	box-shadow: 0 0 20px 10px rgb(0 0 0 / 20%) !important;
}

.bar-container {
	width: 100%;
	border-radius: 10px;
	padding: 20px;
	background-color: #e8e6e6;
	margin-bottom: 20px;
	display: inline-flex;
}
.bar {
	flex: 1;
	height: 15px;
	background-color: #cdcdcd;
}
.main-header {
	width: 100%;
	padding:60px 0;
}
.main-header-container {
	background-image: url('../img/backgroundPattern.png');
	background-repeat: no-repeat;
	background-position: top right;
}
.main-header-btn {
	font-size: 22px;
	font-weight: 500;
	padding-left: 5px;
	padding-right: 5px;
}
.main-header-text {
	font-size: 50px;
	font-weight: 800;
}
.main-header-description {
	font-size: 24px;
	font-weight: 400;
	color: #5f5982;
}
.main-header-text span {
	color: #df7272;
}
.courses-header-text {
	font-size: 45px;
	font-weight: 700;
}
.courses-header-text span {
	color: #df7272;
}
.course-card {
	background: #DBDBDB;
	display: block;
	padding: 25px 15px 15px 15px;
	border-radius: 8px;
}
.course-card h4 {
	font-weight: 900;
	font-size: 22px;
	margin-top: 9px;
	color:#777777;
}
.course-card p{
	color:#777777;
	font-weight: 500;
}
.course-card-icon {
	width: 58px !important;
	height: 58px !important;
	background-color: #fff;
	border-radius: 8px;
	font-size:20px
}
.course-card-btn {
	width: 178px;
	height: 57px;
	font-size: 18px;
	border-radius: 8px;
	border: 0;
	outline: none;
	font-weight: 600;
	background-color: #DF7272;
	color:#fff !important
}
.course-col:nth-child(odd)  .course-card{
	background-color: #DF7272 !important;
}
.course-col:nth-child(odd)  .course-card h4{
	color:#FAF9FF;
}
.course-col:nth-child(odd)  .course-card p{
	color:#FBFBFF;
}
.course-col:nth-child(odd)  .course-card-icon{
	background-color: #E2DDFF;
}
.course-col:nth-child(odd)  .course-card-btn{
	background-color: #DBDBDB;
	color:#777777 !important
}
@media (max-width:575.98px) {
	.main-header-text{
		font-size: 40px;
	}
	.courses-header-text{
		font-size: 36px;
	}
	.main-header-description{
		font-size: 18px;
	}
	.main-header-btn{
		font-size:16px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}